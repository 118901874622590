@tailwind base;
@tailwind components;
@tailwind utilities;

.textfield,
.inputbox {
  @apply border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}
.textfield:hover,
.inputbox:hover {
  border-color: #c7c7c7;
}
.textfield:focus,
.inputbox:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #bee3f8;
  border: 1px solid #63b3ed;
}

.inputbox:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}

/*  */

.textfield,
.slimbox {
  @apply border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5;
}
.textfield:hover,
.slimbox:hover {
  border-color: #c7c7c7;
}
.textfield:focus,
.slimbox:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #bee3f8;
  border: 1px solid #63b3ed;
}

.slimbox:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 3.9rem;
  z-index: 8;
}

.overflow table thead tr {
  overflow-x: scroll;
}

.overflow table thead::after {
  content: "";
  display: inline-block;
  width: 1px;
  background-color: #aba8a8;
  position: absolute;
  right: -1px;
  height: 40px;
  top: 0px;
}

.no-link a {
  pointer-events: none;
}

.input-box {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  padding-left: 0.5rem;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.5715;
  width: 100%;
}

.product .input-box {
  max-width: 100% !important;
}

.input-box .prefix {
  font-weight: 300;
  color: #999;
  height: 100%;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box input:focus {
  border: none !important;
  box-shadow: none !important;
}

.input-box input:hover {
  border: none !important;
}

.input-box input:focus-within {
  border: none;
}

.commonBtn {
  @apply text-white font-medium py-2 px-4 rounded-md flex flex-wrap justify-center align-middle;
}

.list-transition-enter {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  max-height: 40px;
  color: transparent;
}
/* This is where we can add the transition*/
.list-transition-enter-active {
  top: 45px;
  max-height: 2000px;
  transition: all ease-in-out 800ms;
}
/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
  top: 45px;
  max-height: 200px;
}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
  top: 0;
  max-height: 40px;
  color: transparent;
  transition: all ease-in-out 600ms;
}

.leaflet-control-attribution {
  display: none;
}

* {
  padding: 0;
  margin: 0;
}
.check__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"]:disabled + label .box:after {
  @apply cursor-not-allowed;
}

input[type="checkbox"]:disabled + label {
  @apply text-gray-500 cursor-not-allowed;
}

.service-container {
  display: none;
}

.service-container:has(div > div > div > div) {
  display: block;
}

.main-background {
  background: radial-gradient(ellipse at center, #f4fcff 0%, #1a374d 100%);
}

.with-backdrop {
  @apply bg-white backdrop-blur-xl bg-opacity-20;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsl(0, 0%, 98%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
