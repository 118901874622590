@keyframes slideDialog {
  from {
    top: calc(25% + 50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    top: 25%;
  }
}
.slide-dialog {
  animation: slideDialog 0.3s ease-in-out;
}

@keyframes slideDrawer {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0%);
  }
}
.slide-drawer {
  animation: slideDrawer 0.5s ease-in-out;
}
