.checkbox {
  @apply relative select-none inline-flex items-center mr-5 justify-start pt-2;
}

.checkbox input[type="checkbox"] {
  @apply hidden;
}

.checkbox .box {
  @apply w-5 h-5 bg-white rounded border border-gray-400 mr-2 cursor-pointer inline-flex justify-center items-center relative transition-all;
}

input[type="checkbox"]:disabled + label > span {
  @apply !cursor-not-allowed;
}

.checkbox label {
  @apply flex items-center justify-center cursor-pointer text-sm;
}

.checkbox label:hover .box {
  @apply border-blue-500;
}

.checkbox .check-icon {
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transform: scale(0);
  @apply text-white text-xs;
}

.checkbox input[type="checkbox"]:checked + label .box {
  @apply bg-blue-500 border-blue-500;
}

.checkbox input:checked + label > .box > .check-icon {
  transform: scale(1);
}
.checkbox input:checked + label .box:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  animation: CheckboxEffect 0.36s ease-in-out;
  content: "";
  @apply border-blue-500;
}

@keyframes CheckboxEffect {
  from {
    transform: scale(1);
    opacity: 0.6;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
.label-inactive,
.label-active {
  @apply w-20 text-center rounded-full inline-block py-1 px-2 bg-gray-100 text-xs text-gray-400;
}
.label-active {
  @apply bg-green-100 text-green-500 border-green-200;
}

.label-inactive {
  @apply bg-red-100 text-red-500 border-red-200;
}

.text-error {
  @apply text-red-500;
}

.heading {
  @apply text-xl font-medium leading-none tracking-tight;
}

.tooltip {
  @apply inline-block relative;
}
.tooltipBox {
  @apply absolute z-20 py-1.5 px-2 text-center border border-black rounded-lg transition-all ease-in-out opacity-0 invisible shadow-md bg-black;
}
.tooltipText {
  @apply text-xs text-gray-200;
}

.top {
  @apply left-1/2 -translate-x-1/2 -translate-y-2;
  bottom: calc(100% + 10px);
}
.right {
  @apply top-1/2 -translate-y-1/2 translate-x-2;
  left: calc(100% + 10px);
}
.bottom {
  @apply left-1/2 -translate-x-1/2 translate-y-2;
  top: calc(100% + 10px);
}
.left {
  @apply top-1/2 -translate-y-1/2 -translate-x-2;
  right: calc(100% + 10px);
}

/* arrow common styles */
.tooltipBox:before,
.tooltipBox:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
}
.tooltipBox.top:before,
.tooltipBox.top:after,
.tooltipBox.bottom:before,
.tooltipBox.bottom:after {
  left: 50%;
  transform: translateX(-50%);
  border-top-color: black;
}
.tooltipBox.left:before,
.tooltipBox.left:after,
.tooltipBox.right:before,
.tooltipBox.right:after {
  top: 50%;
  transform: translateY(-50%);
  border-left-color: black;
}

/* arrow width */
.tooltipBox.top:after,
.tooltipBox.right:after,
.tooltipBox.bottom:after,
.tooltipBox.left:after {
  border: 6px solid transparent;
}

.tooltipBox.top:before,
.tooltipBox.right:before,
.tooltipBox.bottom:before,
.tooltipBox.left:before {
  border: 7px solid transparent;
}

/* arrow color */
.tooltipBox.top:after {
  border-top-color: black;
}
.tooltipBox.top:before {
  border-top-color: #71717a;
}
.tooltipBox.right:after {
  border-right-color: black;
}
.tooltipBox.right:before {
  border-right-color: #71717a;
}
.tooltipBox.bottom:after {
  border-bottom-color: black;
}
.tooltipBox.bottom:before {
  border-bottom-color: #71717a;
}
.tooltipBox.left:after {
  border-left-color: black;
}
.tooltipBox.left:before {
  border-left-color: #71717a;
}

/* arrow position */
.tooltipBox.top:before,
.tooltipBox.top:after {
  top: 100%;
}
.tooltipBox.right:before,
.tooltipBox.right:after {
  right: 100%;
}
.tooltipBox.bottom:before,
.tooltipBox.bottom:after {
  bottom: 100%;
}
.tooltipBox.left:before,
.tooltipBox.left:after {
  left: 100%;
}

/* on click */
.tooltip:hover .tooltipBox {
  visibility: visible;
  opacity: 1;
}
.tooltip:hover .tooltipBox.top,
.tooltip:hover .tooltipBox.bottom {
  @apply -translate-x-1/2 translate-y-0;
}
.tooltip:hover .tooltipBox.left,
.tooltip:hover .tooltipBox.right {
  @apply translate-x-0 -translate-y-1/2;
}
