
/*image popup*/
.containers{
    width: 95%;
    min-height: 95%;
    position: relative;
    margin: 0 auto;
  }
.containers .media-container .media:hover :is(img){
    transform: scale(1.1);
}
  .containers .popup-media{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
  }
  .containers .popup-media :is(img){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    max-width: 95%;
    max-height: 95%;
    object-fit: contain;
    border: 3px solid #fff;
    z-index: 9999;
  }
  
  .containers .popup-media span{
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 9999;
    cursor: pointer;
    color: #fff;
    user-select: none;
    display: block;
  }
  
  .uploadlebel{
    width: 100%;
    min-height: 100%;
    background: #18a7ff0d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #18a7ff82;
    cursor: pointer;
  }
  .uploadlebel span{
    font-size: 70px;
    color: #18a7ff;
  }
  .uploadlebel p{
    color: #18a7ff;
    font-size: 20px;
  }



  .custom-input {
    display: inline-block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .custom-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

.file-name{
  cursor: pointer;
}
.file-name:hover{
  color: #18a7ff82;
}
  .dismiss-button:hover {
    color: red;
    cursor: pointer;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  }
  
  